import React, { Component } from "react";
import { connect } from "react-redux";
import autobind from "react-autobind";
import _ from "lodash";
import moment from "moment";
import * as $ from "jquery";
import {withRouter} from 'react-router-dom';
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";
import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";
import AddressLookUp from "../../../components/AddrLookUpAutoSuggest/AddrLookUpAutoSuggest";
import * as keysActions from "../../../store/Keys/actions";
import * as keySelectors from "../../../store/Keys/reducer";
import * as addressLookupActions from "../../../store/AddressLookUp/actions";
import * as addressLookupSelectors from "../../../store/AddressLookUp/reducer";
import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";

import "../../../sass/containers/Keys/index.css";
import "../../../sass/components/DateTimePicker/index.css";

class KeyReturnScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "keys",
      keyList: [],
      overlayColor: "",
      keyId: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.keyId
        : "",
      keyLogId: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.keyLogId
        : "",
      phoneNumber: "",
      email: "",
      currentDate: moment(new Date()).format("DD/MM/YYYY"),
      currentTime: moment(new Date()).format("HH:mm "),
      name: "",
      addresslookupId: undefined,
      address: "",
      selectedKey: undefined,
      tenant: "",
      recipient: undefined,
      dateTimeReceived: moment(),
      expectedDateReturn: moment(),
      selectedKeyLog: undefined
    };

    this.props.dispatch(keysActions.getAllKeys());
    this.props.dispatch(keysActions.getKeyStatuses());
    this.props.dispatch(residentActions.getAllResidents());
    this.props.dispatch(addressLookupActions.loadAddresses());
    this.returnKeyValidation = null;
    autobind(this);
  }

  componentWillMount() {
    let overlayColor;
    let currentModule = _.find(this.props.modules, o => o.title === "Keys");
    overlayColor = currentModule && currentModule.imageOverlayTextColour;

    this.setState({
      overlayColor: overlayColor
    });
  }

  componentDidMount() {
    const { keyId } = this.state;
    this.addValidation();
    if (keyId) {
      this.handleKeyLogList([{ keyId: keyId }]);
    }
}

  componentWillUnmount() {
    this.returnKeyValidation.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.keys !== this.props.keys && this.props.keys.length > 0) {
      let currentKey = _.find(this.props.keys, { id: this.state.keyId });
      this.setState({
        selectedKey: currentKey
      });
    }

    if (prevProps.keyLog !== this.props.keyLog && this.props.keyLog.length > 0) {
      let currentKeyLog = _.find(this.props.keyLog, { id : this.state.keyLogId });

     
      if (!_.isUndefined(currentKeyLog)) {
        let keyRecipient = {};

        if (currentKeyLog.recipientType === "Visitor") {
          keyRecipient = {
            name: `${currentKeyLog.visitor.firstName} ${
              currentKeyLog.visitor.surname
              }`,
            phoneNumber: "",
            email: currentKeyLog.visitor.email
          };
        } else {
          keyRecipient = {
            name: `${currentKeyLog.resident.firstName} ${
              currentKeyLog.resident.lastName
              }`,
            phoneNumber: !currentKeyLog.resident.mobileNumber ? "" : currentKeyLog.resident.mobileNumber,
            email: currentKeyLog.resident.email
          };
        }
       
        this.setState({
          selectedKeyLog: currentKeyLog,
          name: `${keyRecipient.name}`,
          phoneNumber: keyRecipient.phoneNumber,
          email: keyRecipient.email,
          expectedDateReturn: moment(currentKeyLog.expectedDateReturn),
          dateTimeReceived: moment(currentKeyLog.dateTimeReceived)
        });
      }
    }
  }
  handleKeyLogList(data) {
    this.props.dispatch(keysActions.getKeyLogByKeyStatus(data));
  }

  handleChange(e) {
    var field = e.target.name;
    var value = e.target.value;
    this.setState({
      [field]: value
    });
  }

  handleDateChange(val) {
    this.setState({
      selectedDate: val
    });
  }

  addValidation() {
    this.returnKeyValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }
  validate(fromSubmit = false) {
    this.returnKeyValidation.validate({ force: true });
    const valid = this.returnKeyValidation.isValid();

    this.setState({
      returnKeyFormValid: valid,
      returnKeyFormUsed: true
    });

    if (valid === true && fromSubmit === true) {
      this.handleReturn();
      this.formReset();
      this.setState({
        returnKeyFormUsed: false
      });
      setTimeout(() => {
        this.props.history.push({
          pathname: "/",
          state: { module: "Keys" }
        });
        }, 1001);

        window.scrollTo(0, 0);
    }
  }
  formReset() {
    this.setState({
      email: "",
      addresslookupId: "",
      phoneNumber: "",
      address: "",
      selectedKey: undefined,
      name: "",
      keyId: undefined,
      keyLogId: "",
      selectedTenant: undefined,
      expectedDateReturn: moment(),
      dateTimeReceived: moment()
    });

    //this.refs["tenant-auto-suggest"].getWrappedInstance().reset();
   // this.refs["addr-auto-suggest"].getWrappedInstance().reset();

    $(this.refs.validate)
      .get(0)
      .reset();

    this.returnKeyValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
  }

  handleReturn() {
    const { keyId, keyLogId } = this.state;

    var data = {
      keyId: keyId,
      keyLogId: keyLogId
    };

    this.props.dispatch(keysActions.returnKey(data, success => {
      if (success === true) {
        this.props.dispatch(keysActions.sendKeyReturnNotification(data, complete => {
          if (complete === true) { }
        }));
      }
    }));
  }
  render() {
    const {
      phoneNumber,
      email,
      address,
      name,
      currentTime,
      dateTimeReceived,
      expectedDateReturn
    } = this.state;

    const currentModule = _.find(this.props.modules, o => o.title === "Keys");
    
    return (
      <div>
        <div className={"container return key"}>
          <FeatureArea
            type={this.state.type}
            areaTagline="Please enter information below"
            pageHeader="Return Key"
            areaTitle="Keys"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            landing={false}
						activeMenu={true}
          />

          {
            <form
              className="content-wrapper double-margin-top"
              ref="validate"
              onSubmit={e => {
                e.preventDefault();
              }}
              data-parsley-errors-container="#validation-messages"
              data-parsley-focus="none"
              style={{ marginTop: 56 }}
            >
              <div className="inputs marginKeys"  >
                <InputComponent
                  label="Name"
                  name="name"
                  placeHolder="Name"
                  handleChange={e => {
                    this.handleChange(e);
                  }}
                  value={name}
                // parsley={{
                //   "data-parsley-type": "Alphanum",
                //   "data-parsley-validate-if-empty": false,
                //   "data-parsley-error-message": "Please enter a valid name"
                // }}
                />
              </div>
              <div className="inputs two-column marginKeys">
                <InputComponent
                  label="Phone Number"
                  name="phoneNumber"
                  placeHolder="phone number"
                  handleChange={e => {
                    this.handleChange(e);
                  }}
                  value={phoneNumber}
                  parsley={{
                    "data-parsley-type": "digits",
                    "data-parsley-minlength": 11,
                    "data-parsley-error-message":
                      "Please enter a valid phone number"
                  }}
                />
                <InputComponent
                  label="Email"
                  name="email"
                  placeHolder="email"
                  handleChange={e => {
                    this.handleChange(e);
                  }}
                  value={email}
                  parsley={{
                    "data-parsley-type": "email",
                    "data-parsley-validate-if-empty": false,
                    "data-parsley-error-message": "Please enter a valid email"
                  }}
                />
              </div>
              <div className="inputs">
                <AddressLookUp
                  ref="addr-auto-suggest"
                  label="Key Address"
                  placeHolder="Search Address"
                  clearableIfValid={false}
                  value={address}
                  defaultAddressId={
                    !_.isUndefined(this.state.selectedKey)
                      ? this.state.selectedKey.lookupAddressId
                      : undefined
                  }
                  onSelected={data => {
                    this.setState({
                      addresslookupId: data.addresslookupId,
                      address: data.address
                    });
                    setTimeout(() => {
                      this.state.returnKeyFormUsed && this.validate();
                    }, 0);
                  }}
                  defaultText={dataVal => { }}
                />
              </div>
              <div className="inputs two-column marginKeys" style={{ marginTop: 4 }}>
                <div className="inputs two-column">
                  <InputComponent
                    label="Date Collected"
                    value={moment(dateTimeReceived).format("DD/MM/YYYY")}
                    handleChange={e => this.handleChange(e)}
                  />
                  <InputComponent
                    label="Time"
                    value={currentTime}
                    handleChange={e => this.handleChange(e)}
                  />
                </div>
                <div className="inputs two-column">
                  <InputComponent
                    type="datePicker"
                    label="Expected Return"
                    name="selectedDate"
                    defaultMin={moment()}
                    defaultSelect={expectedDateReturn}
                    handleDateChange={this.handleDateChange}
                    disabled={true}
                  />


                  <InputComponent
                    type="datePicker"
                    label="Return Date"
                    name="returnedDate"
                    defaultMin={moment()}
                    defaultSelect={moment()}
                    handleDateChange={this.handleDateChange}
                    disabled={true}
                  />
                </div>
              </div>
              <div
                className={
                  "validation-messages" +
                  (this.state.returnKeyFormValid ? " valid" : " invalid")
                }
                id="validation-messages"
              >
                <div>
                  {this.props.keyActionStatus &&
                    !_.isUndefined(this.props.keyActionStatus.action) &&
                    (this.props.keyActionStatus.action === true ? (
                      <p className="message type-success">Key returned</p>
                    ) : (
                        this.state.error && (
                          <p className="message error"> {this.state.error}</p>
                        )
                      ))}
                </div>

              </div>
              <div className="align-center double-margin-top">
                <ButtonGroup
                  sizes={["smedium", "medium"]}
                  leftText={this.props.keyActionStatus &&
                    !_.isUndefined(this.props.keyActionStatus.action) &&
                    this.props.keyActionStatus.action === true ? "Back to Home" : "Cancel"}
                  rightText="Return Key"
                  leftClick={() => {
                    this.props.history.push({
                      pathname: "/",
                      state: { module: "Keys" }
                    });
                    window.scrollTo(0, 0);
                  }}
                  rightClick={e => {
                    this.validate(true);
                  }}
                />
              </div>
            </form>
          }

          {/**End of key assign section */}
        </div>
        <FooterNavigation />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    keys: keySelectors.loadAllKeys(state),
    addressLookup: addressLookupSelectors.getAddresses(state),
    residents: residentSelectors.getResidents(state),
    keyActionStatus: keySelectors.getKeyActionStatus(state),
    keyLog: keySelectors.loadKeysLog(state),
    modules: moduleSelectors.loadModules(state)
  };
}

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(
  KeyReturnScreen
));
