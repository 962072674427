import React, { Component } from 'react';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import _ from 'lodash';
import moment from 'moment';
import * as $ from 'jquery';
import {withRouter} from 'react-router-dom';
import * as moduleSelectors from '../../../store/ServiceModules/reducer';

import * as keysActions from '../../../store/Keys/actions';
import * as keySelectors from '../../../store/Keys/reducer';
import * as addressLookupActions from '../../../store/AddressLookUp/actions';
import * as addressLookupSelectors from '../../../store/AddressLookUp/reducer';
import * as residentActions from '../../../store/Residents/actions';
import * as residentSelectors from '../../../store/Residents/reducer';

import DataListMulti from '../../../components/DetailList/DetailList5Column';

import '../../../sass/containers/Keys/index.css';
import '../../../sass/components/DateTimePicker/index.css';

const searchOption = {
	Address: 'address',
	KeyCode: 'barcode'
};

class KeysScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'keys',
			search: '',
			searchType: 'address',
			validSearch: false,
			keysLog: [],
			keyList: [],
			keyLogList: [],
			overlayColor: '',
			currentKeyList: undefined,
			expectedReturnDate: '',
			name: '',
			recip: [],
			selectedKey: undefined,
			tenantId: '',
			status: ''
		};

		this.props.dispatch(keysActions.getAllKeys());
		this.props.dispatch(keysActions.getKeyStatuses());
		this.props.dispatch(residentActions.getAllResidents());
		this.props.dispatch(addressLookupActions.loadAddresses());
		this.props.dispatch(keysActions.loadKeyLogList(null, null));

		//this.assignKeyValidation = null;
		autobind(this);
	}

	componentWillMount() {
		let overlayColor;

		let currentModule = _.find(this.props.modules, (o) => o.title === 'Keys');
		overlayColor = currentModule && currentModule.imageOverlayTextColour;

		this.setState({
			overlayColor: overlayColor
		});
	}


	componentDidUpdate(prevProps, prevState) {

		/**find over due keys */
		if (this.props.keys !== prevProps.keys && this.props.keys.length > 0) {
			let overdue = _.filter(this.props.keys, (o) => o.status === 'overdue');

			let keyList = _.map(overdue, (item) => {
				return {
					keyId: item.id
				};
			});

			if(keyList && keyList.length > 0){
				this.loadOverdueKeyLog(keyList);
			}
		}

		if (prevProps.statuses !== this.props.statuses && !_.isUndefined(this.props.statuses)) {
			this.setState({
				statuses: this.props.statuses
			});
		}

		if (prevProps.keysLog !== this.props.keysLog && this.props.keysLog.length > 0) {
			this.setState({
				keysLog: _.filter(this.props.keysLog, (o) => o !== null)
			});
		}

		if (prevProps.logList !== this.props.logList) {
			this.setState({
				keyLogList: this.props.logList
			});
		}

		
	}

	handleChange(e) {
		var field = e.target.name;
		var value = e.target.value;
		this.setState({
			[field]: value
		});
	}

	/**handle selected option, deselected to default option (barcode) */
	handleSearchOption(selected) {
		this.setState({
			searchType: this.state.searchType === selected ? '' : selected
		});
	}

	handleKeyLogList(data) {
		this.props.dispatch(keysActions.getKeyLogByKeyStatus(data));
	}

	loadOverdueKeyLog(data) {
		this.props.dispatch(keysActions.loadOverdueKeyLogs(data));
	}
	handleKeyPressSearch(e) {
		const { search, searchType, keyLogList } = this.state;
		const { addressLookup, keys } = this.props;

		let addr_data = undefined;
		let key_collection = [];
		
		let keyList = [];

		if (e.key === 'Enter') {
			if (searchType === searchOption.Address) {
				this.setState({
					validSearch: true
				});

				/**Find address from lookup */
				addr_data = _.find(
					addressLookup,
					(address) => address.addr1FlatNumber.toLowerCase() === search.toLowerCase() || 
					address.addr2BuildingName.toLowerCase() === search.toLowerCase() || 
					address.addr3StreetNumber.toLowerCase() === search.toLowerCase()
				);

				let address_list = _.filter(
					addressLookup,
					(addr) => addr.addr1FlatNumber.toLowerCase() === search.toLowerCase() || 
					addr.addr2BuildingName.toLowerCase() === search.toLowerCase() || 
					addr.addr3StreetNumber.toLowerCase() === search.toLowerCase()
				);

			
				/**Match each key collection to address search results list */	
				let key_addr_data_ =
				keys && keys.length > 0
						? _.map(keys, (keyObj) => {
								let keyAddrVal = _.find(address_list, (addr) => keyObj.lookupAddressId === addr.id);
								if (!_.isUndefined(keyAddrVal)) {

									let address = formatAddress(keyAddrVal.addr1FlatNumber, keyAddrVal.addr2BuildingName, keyAddrVal.addr3StreetNumber, keyAddrVal.addr4Town);

									//let address = `${addrObj.addr1FlatNumber} ${', '} ${addrObj.addr2BuildingName} ${', '} ${addrObj.addr3StreetNumber} ${', '} ${addrObj.addr4Town}`;
									
									let key_add = { ...keyObj, address };
									key_collection.push(key_add);

									return key_add;
								} else{
									return undefined
								}
							})
						: [];

				
				/**for each key record foudn, re form data to custom list for display */
				keyList = _.filter(
					_.map(key_collection, (data) => {
						if (data.lookupAddressId === addr_data.id) {
						}
						let key_Addr = {
							id: data.id,
							status: _.startCase(_.camelCase(data.status)),
							address: data.address ? data.address : '',
							keyid: data.barcode
						};
						
						return key_Addr;

						//return null;
					}),
					(item) => item !== null
				);
			} else if (searchType === searchOption.Resident) {
				this.setState({
					validSearch: true
				});

				/**Check search data for paired name value */
				var split = search.trim().split(' ').filter((value) => {
					return value !== '';
				});

				let residents = [];
				let residentskeyLog = _.filter(keyLogList, (x) => x.recipientType !== 'Visitor');
				let visitorskeyLog = _.filter(keyLogList, {
					recipientType: 'Visitor'
				});

				let visitors = [];

				/**First name condition used */
				if (split.length === 1) {
					residents = _.filter(
						residentskeyLog,
						(item) =>
							item.firstName.toLowerCase() === split[0].toLowerCase() ||
							item.lastName.toLowerCase() === split[0].toLowerCase()
					);

					visitors = _.filter(
						visitorskeyLog,
						(item) =>
							item.firstName.toLowerCase() === split[0].toLowerCase() ||
							item.lastName.toLowerCase() === split[0].toLowerCase()
					);
				} else if (split.length > 1) {
					/**paired name condition. split to first and last name */
					residents = _.filter(
						residentskeyLog,
						(item) =>
							item.firstName.toLowerCase() === split[0].toLowerCase() &&
							item.lastName.toLowerCase() === split[1].toLowerCase()
					);

					visitors = _.filter(
						visitorskeyLog,
						(item) =>
							item.firstName.toLowerCase() === split[0].toLowerCase() &&
							item.lastName.toLowerCase() === split[1].toLowerCase()
					);
				}

			

				let visitorkeyList = _.map(visitors, (item) => {
					return {
						id: item.keyId
					};
				});

				let residentkeyList = _.map(residents, (item) => {
					return {
						id: item.keyId
					};
				});
				keyList = visitorkeyList.concat(residentkeyList);
			} else if (searchType === searchOption.KeyCode) {
				this.setState({
					validSearch: true
				});

				keyList = _.map(_.filter(keys, (o) => o.barcode === search), (item) => {
					let addr = _.find(addressLookup, (address) => address.id === item.lookupAddressId);
					return {
						id: item.id,
						status: item.status,
						address: formatAddress(addr.addr1FlatNumber, addr.addr2BuildingName, addr.addr3StreetNumber, addr.addr4Town)
					
					};
				});
			}
		}

		let logList = _.map(keyList, (o) => {
			return {
				keyId: o.id
			};
		});

		if (logList && logList.length > 0) this.handleKeyLogList(logList);

		this.setState({
			keyList: logList
		});
	}

	handleViewClick(e) {
		let selectKey = _.find(this.props.keys, (o) => o.id === e);
		if (selectKey && !_.isUndefined(selectKey)) {
			let keyStatus = selectKey.status;

			this.setState({
				selectedKey: selectKey,
				addresslookupId: !_.isUndefined(selectKey) ? selectKey.lookupAddressId : undefined
			});

			this.props.history.push({
				pathname: '/concierge/keys/profile',

				state: {
					keyId: selectKey.id,
					selectedKey: selectKey,
					status: keyStatus,
					keyList: this.state.keyList
				}
			});
		}
	}
	handleDateChange(val) {
		this.setState({
			selectedDate: val
		});
	}

	addValidation() {
		this.assignKeyValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}
	validate(fromSubmit = false) {
		this.assignKeyValidation.validate({
			force: true
		});
		const valid = this.assignKeyValidation.isValid();

		this.setState({
			assignKeyFormValid: valid,
			assignKeyFormUsed: true
		});

		if (valid === true && fromSubmit === true) {
			this.formReset();

			this.handleAssign();
			this.setState({
				assignKeyFormUsed: false
			});
		}
	}
	formReset() {
		this.setState({
			email: '',
			addresslookupId: '',
			phone: '',
			address: '',
			selectedKey: undefined,
			tenant: '',
			tenantId: '',
			selectedTenant: undefined,
			selectedDate: moment()
		});

		//this.refs['tenant-auto-suggest'].getWrappedInstance().reset();
		//this.refs['addr-auto-suggest'].getWrappedInstance().reset();

		$(this.refs.validate).get(0).reset();

		this.assignKeyValidation.destroy();

		// Reset all the select input components to the placeholder value
		for (let key in this.refs) {
			if (key.indexOf('formSelect') === 0) {
				this.refs[key].reset();
			}
		}

		setTimeout(() => {
			this.addValidation();
		}, 0);
	}
	handleAssign() {
		const { selectedKey, selectedTenant } = this.state;

		let data;
		if (selectedKey && selectedTenant) {
			data = {
				keyId: selectedKey.id,
				memberId: this.state.tenantId,
				expectedDateReturn: this.state.selectedDate,
				recipientType: !_.isUndefined(selectedTenant)
					? this.state.selectedTenant.memberType
					: !_.isUndefined(this.state.selectedVisitor) ? 'Visitors' : '',
				visitorId: !_.isUndefined(this.state.selectedVisitor) ? this.state.selectedVisitor.id : ''
			};

			this.props.dispatch(keysActions.assignKey(data, (success) => {}));
		}
	}
	render() {
		const { keyList } = this.state;

		const keyStyle = {
			color: this.state.overlayColor
		};

		const { keysLog, keys, addressLookup, overduekeysLog, logList} = this.props;

		//console.log(keyList);
		let currentKeyList = _.filter(
			_.map(keyList, (o) => {
				/**Get key object */
				var keyVal = _.find(keys, (k) => k.id === o.keyId);

				if (_.isUndefined(keyVal)) return null;

				/**Get Get address of key */
				let addr = !_.isUndefined(keyVal)
					? _.find(addressLookup, (data) => data.id === keyVal.lookupAddressId)
					: undefined;
					
				let recipient = {};
				/**Get log for specified key */
				var val = !_.isUndefined(keyVal) ? _.find(logList, (item) => item.keyId === keyVal.id) : undefined;

				
				/**no log found, construct key data */
				if (_.isUndefined(val)) {
					return {
						id: keyVal.id,
						status: _.startCase(_.camelCase(keyVal.status)),

						type: keyVal.recipientType ? keyVal.recipientType : '',
						name: recipient ? recipient.name : '',

						address: addr ? formatAddress(addr.addr1FlatNumber, addr.addr2BuildingName, addr.addr3StreetNumber, addr.addr4Town)  : '',

						keyid: keyVal.barcode

					};
				}

				/**log data for key found, conustruct key log data */
				
				//if (val && val.recipientType === 'Visitor') {
					recipient = {
						name: `${val.firstName} ${val.lastName}`
					};
				//} else if (val && val.recipientType !== 'Visitor') {
				//	recipient = {
				//		name: `${val.resident.firstName} ${val.resident.lastName}`
				//	};
				//}

			
				return {
					id: val.keyId,
					status: _.startCase(_.camelCase(keyVal.status)),
					type: val.recipientType,
					name: recipient ? recipient.name : '',

					address: addr ? formatAddress(addr.addr1FlatNumber, addr.addr2BuildingName, addr.addr3StreetNumber, addr.addr4Town)  : '',

					//address: addr ? `${addr.addr1FlatNumber} ${', '} ${addr.addr2BuildingName} ${', '} ${addr.addr3StreetNumber} ${', '} ${addr.addr4Town}` : '',
					
					keyid: keyVal.barcode

				};
			}),
			(item) => item !== null
		);

		
		currentKeyList.sort((a,b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0)); 


		/**construct the new overdue list to include visitor  */
		let newOverdueList = [];
		if (overduekeysLog.length > 0) {
		
			/**Iterate through current overdue log list, use map as array will be reconstructed */
			newOverdueList = _.filter(
				_.map(overduekeysLog, (keyItem) => {
					let recipient = {};
					
					/**find key data related to each keylog items */
					let keydata =
						!_.isUndefined(keyItem) && !_.isNil(keyItem)
							? _.find(keys, (o) => o.id === keyItem.keyId)
							: undefined;

					if (_.isUndefined(keyItem) || _.isNil(keyItem)) return undefined;

					/**get the relevent address object for each key data */
					let addr = !_.isUndefined(keydata)
						? _.find(addressLookup, (data) => data.id === keydata.lookupAddressId)
						: undefined;
                   
					/**each key data has a recipent type, check the type and apply the recipient object for the required type */
					if (keyItem.recipientType === 'Visitor') {
						recipient = {
							name: `${keyItem.visitor.firstName} ${keyItem.visitor.surname}`
						};
					} else {
						
						recipient = {
							name: `${keyItem.resident.firstName} ${keyItem.resident.lastName}`
						};
					}

					/**the log list re-constructed to datalist needed values */
					return {
						id: keyItem.keyId,
						status: _.startCase(_.camelCase('overdue')),
						name: recipient.name,

						address: addr ? formatAddress(addr.addr1FlatNumber, addr.addr2BuildingName, addr.addr3StreetNumber, addr.addr4Town)  : '',

						//address: addr ? `${addr.addr1FlatNumber} ${', '} ${addr.addr2BuildingName} ${', '} ${addr.addr3StreetNumber} ${', '} ${addr.addr4Town}` : '',
						type: keyItem.recipientType,
						keyid:  keydata.barcode

					};
				}),
				(d) => {
					return !_.isUndefined(d);
				}
			);
		}
		
		return (
			<div className={'container'}>
				{' '}
				{/**Search form */}{' '}
				<div className={'content-wrapper type-' + this.state.type}>
					<label> Search </label> {' '}
					<input
						type="text"
						name="search"
						onChange={this.handleChange}
						placeholder="Enter details to search"
						onKeyPress={(e) => this.handleKeyPressSearch(e)}
					/>{' '}
					{' '}
				</div>{' '}
				{' '}
				<div className="options">
					<div
					id="addressOption"
						className={this.state.searchType === searchOption.Address ? 'underLine' : ''}
						onClick={(e) => {
							this.handleSearchOption(searchOption.Address);
						}}
					>
						Address {' '}				
					</div>{' '}
					{' '}
			
					{' '}
					<div
						
					id="barcodeOption"
					className={this.state.searchType === searchOption.KeyCode ? 'underLine' : ''}
					onClick={(e) => {
						this.handleSearchOption(searchOption.KeyCode);
					}}
				>
					Key ID/Code {' '}



					</div>{' '}
					{' '}
				</div>{' '}
				{' '}
				{this.state.validSearch &&
				this.state.keyList.length === 0 && (
					<p className="notFound">We could not find any items related to your search</p>
				)}{' '}
				{this.state.validSearch &&
				this.state.validSearch === true &&
				this.state.keyList.length !== 0 && (
					<div id="searchTable">
						<DataListMulti
							data={currentKeyList}
							listType="key-view-list"
							headingsAlone={true}
							actionClick={(e) => this.handleViewClick(e)}
							emptyMessage=""
						/>
					</div>
				)}
				{/** End of search form and list*/}
				<div id="overdue" className="overdue">
					<h1 style={keyStyle}> Keys Overdue </h1>{' '}
					<DataListMulti
						data={newOverdueList}
						listType="key-view-list"
						headingsAlone={true}
						actionClick={(e) => this.handleViewClick(e)}
						emptyMessage=""
					/>{' '}
					{newOverdueList.length === 0 && <p className="notFound"> There are currently no overdue keys </p>} {' '}
				</div>{' '}
				{' '}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		keys: keySelectors.loadAllKeys(state),
		addressLookup: addressLookupSelectors.getAddresses(state),
		residents: residentSelectors.getResidents(state),
		//keyActionStatus: keySelectors.getKeyActionStatus(state),
		//assignedKey: keySelectors.assignedKey(state),
		overduekeysLog: keySelectors.getOverdueKeyLog(state),
		modules: moduleSelectors.loadModules(state),
		statuses: keySelectors.loadkeyStatuses(state),
		keysLog: keySelectors.loadKeysLog(state),
		logList: keySelectors.getKeyLogList(state)
	};
}

function formatAddress(add1, add2, add3, add4) {
	var addressFormatted = '';
	if (add2) {
		addressFormatted = `${add1}${', '}${add2}${', '}${add3}${', '}${add4}`;
	}
	else
	{
		addressFormatted = `${add1}${', '}${add3}${', '}${add4}`;
	}
	return addressFormatted;
}

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(KeysScreen));
